import './SeparatorPage.css'

function SeparatorPage() {
    return(
        <div className="separator_page_container">
            <div className="separator_page_img"></div>
        </div>
    )
}

export default SeparatorPage