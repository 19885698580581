const ParticipantsData = [
  {
    CompanyName:"Учебный Центр Окей",
    Name: '"Математическая игротека"',
    Age: '6+',
    Description:"Что такое математическая игротека?\nИгротека – значит здесь играют? \nПо известному выражению Бернарда Шоу, «Мы не потому перестаем играть, что постарели, — мы стареем, потому что перестаём играть».\nИграть любят все, и дети, и взрослые.\n\nНо почему наша игротека математическая?\nПотому что, во-первых, мы любим математику. А во-вторых, потому, что есть очень, очень много интересных игр и занятий, которые напрямую связаны с математикой.\nИ мы хотим показать их вам и поиграть с вами вместе.\n\nНа игротеке можно будет сделать своими руками модели объёмных геометрических тел и загадочные фигурки-флексагоны, сложить красивые узоры из математической мозаики, поиграть в настольные игры, посоревноваться в решении головоломок и многое другое.\nУ нас найдётся занятие для всей семьи. Мы ждём и взрослых, и детей. Приходите, будет интересно!",
    SocialNetwork: {
    Instagram: "",
    Facebook: "",
    Telegram: "",
    },
    AggregatorLink:"",
    Genre:"мастер-класс",
    Date:"1 июля, 14:00-18:00",
    id: 10987,
    },

    {
      CompanyName:"Роланд Азиев",
      Name: '"Мастерская граффити"',
      Age: '11+',
      Description:"Уникальная возможность стать настоящим уличным художником. \n1 июля в 12.30 художник граффити Роланд Азиев расскажет подросткам об основах и особенностях росписи стен, о том, почему граффити – это не всегда вандализм, а иногда настоящее искусство. Затем они вместе сделают свои собственные эскизы для граффити. А на следующий день ребята во главе с Роландом отправятся к Пирамиде и на стене воплотят в реальность свои эскизы!\nВ итоге после двухдневной мастерской в городе появится арт-объект, сделанный участниками фестиваля!",
      SocialNetwork: {
      Instagram: "",
      Facebook: "",
      Telegram: "",
      },
      AggregatorLink:"https://eventbuzz.co.il/graffity",
      Genre:"мастер-класс",
      Date:"1 июля, 12.30-14.30",
      id: 109876,
      },
  {
   CompanyName:"Kikar Studio",
   Name: '"Друг к другу"',
   Age: '5+',
   Description:'Мастер-класс в технике теневого театра "Друг к другу" от Kikar Studio.На мастер классе вы узнаете как сделать театр из лампы, кусочка проволоки и пары театральных секретов. Участники мастер-класса:-проведут световые эксперименты;-узнают секрет преображения  бытовых предметов в арт-объекты;-научатся делать теневых кукол;-примут участие в спектакле.Ведущий: режиссёр и педагог Пётр Чижов, автор спектаклей в драматическом и кукольном театрах.Длительность: 1 час 10 мин Количество человек: 15 детей с родителями',
   SocialNetwork: {
   Instagram: "https://www.instagram.com/kikar_studio?igsh=MWcxbWd6dTF4dGxjag==",
   Facebook: "https://www.facebook.com/profile.php?id=61550568539176",
   Telegram: "",
   },
   AggregatorLink:"https://eventbuzz.co.il/kikarstudio",
   Genre:"мастер-класс",
   Date:"1 июля, 13.00",
   id: 109845,
   },

   {
    CompanyName:"Оксанa Бондаревa",
    Age: "6+",
    Name: "НЕрисующие художники",
    Description: 'Мастер-класс Оксаны Бондаревой "НЕ рисующие художники". Оксана расскажет детям, как так вышло, что можно быть художником и не рисовать вовсе и познакомит ребят с альтернативными творческими инструментами. Каждый создаст собственную работу, а в конце мастер-класса устроим минивыставку для родителей.',
    SocialNetwork: {
   Instagram: "",
    Facebook: "",
    Telegram: "",
    },
   AggregatorLink:"https://eventbuzz.co.il/lp/event/khudozniki",
    Genre:"мастер-класс",
    Date:"1 июля, 14.30",
    id: 109846,
    },

   {
    CompanyName:"Летал и Шагал",
    Name: '"Сломанное не ломается"',
    Age: '6+',
    Description:'Рисовально-коллажный мастер-класс. Мы создадим археологическую находку, например, амфору, вазу, тарелку или чашку, соберём ее из фрагментов, желающие восстановят ее в японской технике "кинцуги" ("золотой заплатки") и ребенок унесет домой практические музейный экспонат!Придумываем, рисуем, режем, клеим. Худстудия "Летал и Шагал" - это: - культура и искусство как среда для развития личности- формирование насмотренности и художественного вкуса- развитие творческого потенциала и изобразительных навыков',
    SocialNetwork: {
   Instagram: "https://www.instagram.com/letalishagal/",
    Facebook: "https://www.facebook.com/letalishagal",
    Telegram: "https://t.me/letalishagal",
    },
   AggregatorLink:"https://eventbuzz.co.il/lp/event/letalshagal",
    Genre:"мастер-класс",
    Date:"1 июля, 15.00",
    id: 109847,
    },

    {
      CompanyName:"Inesstudio",
      Name: 'Мода и куклы',
      Age: '8+',
      Description:'Приглашаем вас на уникальный мастер-класс "Мода и куклы"! Погрузитесь в волшебный мир, где сцена с настоящим сценическим кругом и атмосферные декорации создают ощущение полного погружения в театральное действие. Мы объявляем тему и стиль костюмов, а вы создаёте свои шедевры из бумаги. Это не только развивает творческий потенциал, но и помогает перенести действие поближе к современной жизни, делая его более понятным и актуальным. Через костюмы мы исследуем вечные темы — любовь, ненависть, дружбу и предательство. Люди всегда остаются такими же, меняется лишь их одежда. Мы также попробуем создавать эскизы как делают профессиональные художники. Вместе с нашими куклами мы пройдем по театральному подиуму под музыку, создавая настоящее шоу! И каждый участник сможет взять с собой свою «модную модель».Возраст участников: 8-12 лет',
      SocialNetwork: {
      Instagram: "Межова Инесса",
      Facebook: "Mezhovainessa4",
      Telegram: "",
      },
     AggregatorLink:"https://eventbuzz.co.il/moda",
      Genre:"мастер-класс",
      Date:"1 июля, 16.00",
      id: 10987676,
    },  

    {
      CompanyName:"Finkel and Shtein",
      Age: '10+',
      Name: 'Ювелирное чудо',
      Description:'Если вам давно хотелось попробовать сделать что-то своими руками, но вы не знали с чего начать, то это оно! На мастер классе мы будем создавать небольшие подвески из латуни с надписями и фактурами Мы будем учиться резать металл, обрабатывать его огнем, шлифовать и стучать молотком В конце каждый уйдет с кулоном ручной работы и новыми навыкам.',
      SocialNetwork: {
      Instagram: "",
      Facebook: "",
      Telegram: "",
      },
      AggregatorLink:"https://eventbuzz.co.il/lp/event/chudo",
      Genre:"мастер-класс",
      Date:"1 июля, 15.00",
      id: 10987657,
      },

    {
      CompanyName:"Ольга Ким",
      Name:"Пряничные Муми-Тролли",
      Age: '5+',
      Description:"Кулинарный Мастер-класс. Погрузитесь в волшебный мир Муми-дола вместе с нашиммастер-классом по украшению пряников.Вместе с кондитером Ольгой Ким вы сможете украсить ароматные пряники на тему Муми-Троллей)Раскрасим вместе всем известных героев Туве Янсон!Муми-тролль, Мумипапа, Мумимама, Фрекен Снорк, Снусмумрик, Филифьонка, Хемуль, Малышка Мю и Снифф.Кто ваш любимый персонаж?5+Билеты приобретаются на каждого участника",
      SocialNetwork: {
     Instagram: "https://www.instagram.com/kim.bakeat/",
      Facebook: "",
      Telegram: "",
      },
     AggregatorLink:"https://eventbuzz.co.il/pryanik",
      Genre:"мастер-класс",
      Date:"1 июля, 17.00 и 18.00",
      id: 10987678,
    },

    {
      CompanyName:"Елена Макарова",
      Name:"Лепим и рисуем с Еленой Макаровой",
      Age: '5+',
      Description:'Елена Макарова, арт-терапевт, писатель, куратор международных выставок. Ее книги "Как вылепить отфыркивание?", "Освободите слона", "В начале было детство", "Тайм код" и многие другие открывали мир ребенка нам и нашим родителям. О семинарах Елены ходят легенды. 1 июля на фестивале "В Чемодане" будет редкая возможность полепить и порисовать с Еленой всей семьей.',
      SocialNetwork: {
      Instagram: "https://www.instagram.com/lena.makarova51",
      Facebook: "https://www.facebook.com/Makarovaelena2",
      Telegram: "",
      },
      AggregatorLink:"https://eventbuzz.co.il/art-makarova",
      Genre:"мастер-класс",
      Date:"1 июля, 17.30",
      id: 1098767,
    },

    {
      CompanyName:"Николай Санчук",
      Name:"Рыцарские бои на мечах",
      Age: '5+',
      Description:'Фехтование на мечах это спортивные занятия, где дети учатся владеть своим телом, работать в команде,  контролировать  свои эмоции, увеличивает адаптивность в школе, лидерству и начальным навыкам самообороны. \nУже проверено, что когда дети сражаются на мечах, это дает им  возможность выпускать  свои накопленные эмоции.\nСражения будут проходить команда на команду или каждый сам за себя. Бои проходят на мечах и щитах из мягких полимерных материалов\nСеансы стартуют каждый полчаса. \n\nЗанятия ведет Николай Санчук, тренер с 2009 года. Сооснователь в России Федерации фехтования на мечах. Обучил чемпиона мира и 9 чемпионов России.',
      SocialNetwork: {
      Instagram: "",
      Facebook: "",
      Telegram: "",
      },
      AggregatorLink:"--",
      Genre:"мастер-класс",
      Date:"1 и 2 июля, 17.00-20.00",
      id: 10987679,
    },

    {
      CompanyName:"Yuliya Armfeld for kids",
      Name: 'Чтение с продолжением',
      Age: '4+',
      Description:"Сколько вещей можно вместить в чемодан?Как собрать самое необходимое для путешествия?Как меня встретят на новом месте?Ответы на эти и другие вопросы, будем искать с читателями, пришедшими на Мастер – класс «Чтение с продолжением».Наша книга – «Чемодан», Криса Нейлор-Баллестероса))). В ней собрано много тем для обсуждения и размышления. Мы не расстанемся с книгой, дочитав до последней строчки – мы пойдём дальше:- починим разбитую чашку- украсим рамку для фотографии- в неё можно будет вставить любимую фотографию Приглашаются читатели от 6 лет",
      SocialNetwork: {
      Instagram: "",
      Facebook: "https://www.facebook.com/armfeldjulia/  https://www.facebook.com/ebruarthaifa/",
      Telegram: "https://www.instagram.com/ebruarthaifa?igsh=MXRrNnd4M2xjOWJ0eg%3D%3D&utm_source=qrhttps://www.instagram.com/classes_for_kids_haifa?igsh=dGx1aG14aXV2Ymd2&utm_source=qr",
      },
      AggregatorLink:"https://eventbuzz.co.il/chtenie",
      Genre:"мастер-класс",
      Date:"2 июля, 14.00",
      id: 10987680,
    },  

    {
      CompanyName:"Геннадий Фомин и центр Colirbi ",
      Name: "Играть – ум с красотой развивать",
      Age: '8+',
      Description:"Tеатральный мастер-класс. В программе тренинга: 🎭 техники эмоционально позитивного раскрытия темперамента;🎭ритмо-импровизационный голосовой тренинг;🎭имитационная пластика: от животных до походок людей. Будем играть, хулиганить, знакомиться, смеяться, будить игровую природу и собственную заразительность. Геннадий Фомин - актер театра и кино, педагог, режиссёр",
      SocialNetwork: {
      Instagram: "",
      Facebook: "https://www.facebook.com/genfomin/",
      Telegram: "",
      },
     AggregatorLink:"https://eventbuzz.co.il/fomin",
      Genre:"мастер-класс",
      Date:"2 июля, 15.00",
      id: 10987612,
    },

    {
      CompanyName:"Ольга Ким",
      Name:"Шоколадный бенто-торт с героями Amongus, Minecraft и Brawl stars",
      Age: '5+',
      Description:"Присоединяйтесь к нашему мастер-классу и создайте свой уникальный мини-десерт, который станет идеальным подарком для близких или украшением вашего праздничного стола!Мы приготовим вкуснейший шоколадный бенто-тортBento — в переводе с японского значит «порция на одного». Этим термином в азиатских странах может называться не только сладость, но и просто коробочка еды на один прием пищи для одной персоны.На мастер-классе с кондитером Ольгой Ким каждый участник создаст свой мини торт и украсит его разными героями из компьютерных игр! Майнкрафт, amongus, brawl stars. Включаем вашу фантазию и вперед5+Билет приобретается на каждого участника",
      SocialNetwork: {
        Instagram: "https://www.instagram.com/kim.bakeat/",
        Facebook: "",
        Telegram: "",
      },
      AggregatorLink:"https://eventbuzz.co.il/bentotort",
      Genre:"мастер-класс",
      Date:"2 июля, 15.00, 18.00, 19.00",
      id: 10987682,
    },

    {
      CompanyName:"Галина Галинова",
      Name: 'Декоративная роспись свечей',
      Age: '11+',
      Description:"Приглашаем детей и взрослых на увлекательный мастер-класс по росписи свечей! Здесь вы сможете создать красивые и оригинальные свечи, которые украсят ваш дом и добавят в него тепла и уюта.Возраст участников: 11+",
      SocialNetwork: {
     Instagram: "https://www.instagram.com/galyadecor?igsh=aWtlNTZtczZyNnA2",
      Facebook: "",
      Telegram: "",
      },
     AggregatorLink:"https://eventbuzz.co.il/svecha",
      Genre:"мастер-класс",
      Date:"2 июля, 16.30",
      id: 83,
    },  

    {
      CompanyName:"Ядвига Шишкина",
      Age: '5+',
      Name: 'Делаем сундучок для ваших сокровищ!',
      Description:'Мастер-класс "Делаем сундучок для ваших сокровищ!" Что использовали для путешествий до появления чемоданов? Конечно, сундуки! На мастер-классе мы будем декорировать деревянный сундучок, который в дальнейшем может стать пеналом.Ведущая мастер-класса - Ядвига Шишкина, дизайнер собственного бренда одежды и художник с высшим образованием. Вас ждёт множество интересных материалов и идей.',
      SocialNetwork: {
     Instagram: "",
      Facebook: "https://www.facebook.com/yadviga.shishkina.1",
      Telegram: "",
      },
     AggregatorLink:"https://eventbuzz.co.il/sunduk",
      Genre:"мастер-класс",
      Date:"2 июля, 18.00",
      id: 84,
      },

      {
        CompanyName:"Пижамная библиотечка и Юлия Армфельд",
        Age: '6+',
        Name: 'Почему у собаки мокрый нос?',
        Description:'Игра-исследование от "Пижамной библиотечки" по мотивам книги шотландского писателя Кеннета Стивена. "Давным-давно — так давно, что мир был еще совсем новенький, — пошел дождь. Да такой сильный, что не осталось на земле ни одного сухого места. И вот один человек по имени Ной построил огромный корабль, чтобы спасти людей и животных. Ной собрал всяческих животных, какие только бывают на свете. Он взял с собой даже слизняков и пауков, жуков и всяких противных букашек. Они провели в море двадцать дней и двадцать ночей, и вот однажды ковчег дал течь. «Что же делать?» — ужаснулся Ной. Кто спасет ковчег? Ведущая - Юлия Армфельд. Участие в этом мастер-классе - бесплатное. Его дарит участникам Фестиваля "В чемодане" программа "Пижамная библиотечка".',
        SocialNetwork: {
        Instagram: "",
        Facebook: "https://www.facebook.com/yadviga.shishkina.1",
        Telegram: "",
        },
        AggregatorLink:"",
        Genre:"мастер-класс",
        Date:"2 июля, 18.00",
        id: 844,
        },

      ////спектакли/////

      {
        CompanyName:"Театрон Маток",
        Name:'"Пингвикинги"',
        Age: '5+',
        Description:"Они спасут мир, но это не точно.По мотивам сказки детского писателя Антона Соя. Режиссёр: Константин Соя.Однажды маленький пингвин Пиня очутился на полярной станции.Полярник, с которым он там познакомился, рассказал ему о викингах, и Пиня тут же сам решил стать воином и отправится на поиски приключений вместе с командой своих друзей пингвинов.Продолжительность спектакля 40 минут. Для детей 3–8 лет. После спектакля — весёлая фотосессия с актёрами.Информация по поводу следующих спектаклей и спектаклей в других городах в инстаграме театра @teatron_matok",
        SocialNetwork: {
       Instagram: "https://www.instagram.com/teatron_matok?igsh=MWdqY2k1djA5em9zdA==",
        Facebook: "https://www.facebook.com/profile.php?id=61557979210966",
        Telegram: "",
        },
       AggregatorLink:"https://eventbuzz.co.il/pingvikingi",
        Genre:"спектакль",
        Date:"1 июля 12.00",
        id: 4454,
        },
        {
          CompanyName:"Театр ARTель",
          Name: '"Как у Моцарта уши арестовали"',
          Age: '5+',
          Description:"История, как у Моцарта уши арестовали.Спектакль с живой музыкой и очень  активным участием зрителей.  Вас ожидают: -ожившие портреты-смешные и грустные подробности из жизни семьи Моцартов- встреча с императрицей и её удивительным нарядом- и, наконец, таинственная история о похищеной партитуре.Все зрители принимают самое активное участие в спектакле!5+",
          SocialNetwork: {
         Instagram: "https://www.instagram.com/artel_teatr/",
          Facebook: "",
          Telegram: "",
          },
         AggregatorLink:"https://eventbuzz.co.il/artel",
          Genre:"спектакль",
          Date:"1 июля, 14.00",
          id: 4455,
          },   /*
          {
            CompanyName:"ArtFabrika ",
            Name: '"Восточный экспресс"',
            Age: '12+',
            Description:"Подростковая группа театральной труппы АртФабрики предлагает вам отправиться в незабываемое путешествие на «Восточном экспрессе». Только будьте осторожны, в пути может случиться что угодно! Но не переживайте, яркие и смелые герои, созданные Агатой Кристи, помогут вам выбраться из любой передряги. Или нет? Или это вам придется помогать героям найти убийцу, распутать непростое дело и помочь остальным добраться до точки назначения.",
            SocialNetwork: {
           Instagram: "",
            Facebook: "",
            Telegram: "",
            },
           AggregatorLink:"",
            Genre:"спектакль",
            Date:"1 июля, 15.00",
            id: 4456,
            },    */
            
  {
    CompanyName:"Cпектакль Анатолия Белого и Татьяны Марек",
    Name: '"Натан"',
    Age: '16+',
    Description:'Продолжительность: 1 час 40 минут. Премьера! Анатолий Белый с новым спектаклем "Натан"! Герой Анатолия Белого и его друг, говорящий енот, захватывают власть, устанавливают новые порядки и организовывают беспорядки, объявляют войны и заключают мир. Такого Анатолия Белого на израильской сцене вы ещё не видели: буря и натиск иронии и фантасмагории, что, впрочем, не отменяет нежности и лиризма. Найдут ли енот и еврей свой путь в хаосе идей? Смогут ли сохранить причудливый союз? Ответы — в израильской премьере спектакля "Натан". Режиссёр — Татьяна Марек.',
    SocialNetwork: {
   Instagram: "",
    Facebook: "",
    Telegram: "",
    },
   AggregatorLink:"https://11879.kassa.co.il/",
    Genre:"спектакль",
    Date:"1 июля, 19.30",
    id: 4457,
    },   
    
    {
      CompanyName:"Craft Theatre",
      Name: '"Девочка с головой волка"',
      Age:'12+',
      Description:'Театрализованная читка пьесы А.Житковскогою "Подростковая группа театральной студии Craft Theatre. \n\nПьеса Алексея Житковского «Девочка с головой волка», написанная в жанре фэнтези-антиутопия, стала победителем международного конкурса новой драматургии \"Ремарка\" в 2020 году в категории \"Маленькая Ремарка 12+\". \n\nНа первый взгляд эта пьеса – о подростках. Здесь есть и тема инаковости, и непонимание родителей... Но есть в пьесе и научный прогресс, который начинает уродовать мир, животный и человеческий, и навязанная толерантность, когда уже непонятно, кто жертва, а кто агрессор. \n\nРавнодушие, любовь, непонимание, доброта и жестокость тесно переплетены, что неясно, где границы между добром и злом. И эта сложность жизни, переданная через фантастические реалии, оказывается той самой страшной \"сказкой о любви к природе\".',
      SocialNetwork: {
      Instagram: "https://www.instagram.com/craf_t_heatre/",
      Facebook: "https://www.facebook.com/craft.theatre",
      Telegram: "",
      },
      AggregatorLink:"",
      Genre:"спектакль",
      Date:"2 июля, 13.00",
      id: 4459,
      }, 
      
   {
    CompanyName:'Театрон "Самэах"',
    Name: '"Человечек из часов"',
    Age:'4+',
    Description:'"ЧЕЛОВЕЧЕК ИЗ ЧАСОВ" - фантазия на стихи Саши Черного. Интерактивный, музыкал ный спектакль сэлементами театра кукол. Атмосферная трогательная история о мальчике, который не может уснуть, вспоминая свой день, свои игры, события, которые случились с ним. Зрители станут участниками его игр.И дождутся, когда герой все таки уснет в своей кроватке.А после спектакля можно будет сфотографироваться и поиграть с подушками.Продолжительность спектакля 1 часСпектакль- лауреат международного фестиваля "Монокль"Играет Рита СмирноваРежиссер Владимир МихельсонКомпозитор Владимир ОрловХудожник Ольга Петровская.',
    SocialNetwork: {
   Instagram: "https://www.instagram.com/sameach.teatron/",
    Facebook: "https://www.facebook.com/tvorita",
    Telegram: "",
    },
   AggregatorLink:"https://eventbuzz.co.il/chelovechek",
    Genre:"спектакль",
    Date:"2 июля, 13.30",
    id: 4458,
    }, 
 

  {
   CompanyName:"Hug U",
   Name:'"Сказки из маминой сумки"',
   Age:'3+',
   Director: 'Марфа Горвиц',
   Description:"Спектакль «Сказки из маминой сумки» Марфы Горвиц для самых маленьких. Из подручных средств создается настоящий домашний камерный театр, где расчески - это ежики, шарики - цветы, а детские ботиночки мышата. Трогательный спектакль, который понравится и детям и взрослым. Проверено!)",
   SocialNetwork: {
    Instagram: "https://www.instagram.com/hug.u.event?igsh=MWVmcGR4YTBpMmkzcg%3D%3D&utm_source=qr",
    Facebook: "https://www.facebook.com/hug.u.event?mibextid=LQQJ4d",
    Telegram: "",
   },
   AggregatorLink:"https://eventbuzz.co.il/maminasumka",
   Genre:"спектакль",
   Date:"2 июля, 15.00 и 17.00",
   id: 4460,
   },

   {
    CompanyName:"ДОМ чёрной СОВЫ",
    Name:'"Все просто"',
    Age:'5+',
    Description:"Как танцует целлофановый пакет? Что может рассказать ведро с краской? О чем поет старая лампочка? Иногда самые простые вещи, окружающие нас в повседневной жизни, могут поведать удивительные истории. Этот спектакль позволит по-новому взглянуть на мир вокруг. Спектакль без слов для всей семьи.",
    SocialNetwork: {
   Instagram: "https://www.instagram.com/blackowlhouse",
    Facebook: "https://www.facebook.com/blackowlhouse",
    Telegram: "",
    },
   AggregatorLink:"https://eventbuzz.co.il/vseprosto",
    Genre:"спектакль",
    Date:"2 июля, 16.00",
    id: 4461,
    }, 

   {
    CompanyName:"Трио Новая Голландия",
    Name:'"О маленьком короле и больших кастрюлях"',
    Age:'5+',
    Description:'Ансамбль старинной музыки "Новая Голландия" приглашает на концертом для детей 3-8 лет.Павел Андреев, Юлия Лурье и Антон Андреев расскажут и сыграют ребятам "Сказку о маленьком короле и больших кастрюлях". Исторические факты о жизни и совместном творчестве композитора Жана-Батиста Люлли и короля Людовика IV в этой истории перемешиваются с сюжетами и героями из сказок Шарля Перро. Маленькие слушатели должны будут их вспомнить, и это поможет спасти короля.Прозвучит музыка придворных композиторов Людовика IV - Люлли, Оттетера, Маре, Делаланда, а также Вивальди, Шнитке и Чайковского.Идея такова - музыканты уверены, что играют концерт настоящей серьезной классики, дети же, по замыслу артистов, считают, что слушают занимательную сказку. Это позволит ребятам сосредоточиться и даст ключи к пониманию образов и эмоций, зашифрованных в музыке. Дети становятся опытными слушателями незаметно для самих себя.Билеты приобретаются на каждого зрителя',
    SocialNetwork: {
   Instagram: "",
    Facebook: "",
    Telegram: "",
    },
   AggregatorLink:"https://eventbuzz.co.il/skazka",
    Genre:"спектакль",
    Date:"2 июля, 18.00",
    id: 4462,
    }, 

    ///презентация/лекция///

  
    {
      CompanyName:"Анна Ремез",
      Name: 'Писательский скетчинг для подростков',
      Age: '12+',
      Description:'Нарисуй словами. Выполним простое писательское упражнение "Сенсорная открытка", описав пять недавних впечатлений на основе пяти чувств: зрения, вкуса, запаха, слуха, осязания. Короткий текст поместится на открытку, которую потом можно будет отправить по почте!',
      SocialNetwork: {
     Instagram: "https://www.instagram.com/remezzz/",
      Facebook: "https://www.facebook.com/anna.remez.7/",
      Telegram: "https://t.me/anechkaremez",
      },
     AggregatorLink:"",
      Genre:"презентация",
      Date:"1 июля,16.00",
      id: 172,
      },   

      {
        CompanyName:"Вера Сорока",
        Name: 'Презентация романа "Питерские монстры"',
        Age: '15+',
        Description:"Как создать миф и можно ли придумать что-то принципиально новое в ХХI веке? Чем монстры отличаются от чудовищ, кто живет в каналах и на чердаках Петербурга, что продается в магазине ненаписанных книг и где грань между реальностью и фантазией в пространстве литературы? Об этом и многом другом расскажет писательница Вера Сорока, автор романа «Питерские монстры». В дискуссии примет участие руководитель маркетинговой редакции издательской группы «Альпина» Глеб Гавриш. Наши гости представят книгу и поговорят о современном мифе, эскапизме в тексте и жизни (тут они постараются действовать аккуратно, избегая спойлеров) и о том, почему монстры бывают человечнее людей. На встрече будет разыграна книга «Питерские монстры» с автографом автора.",
        SocialNetwork: {
        Instagram: "",
        Facebook: "",
        Telegram: "",
        },
        AggregatorLink:"",
        Genre:"презентация",
        Date:"1 июля, 17.00",
        id: 4463,
        },

        {
          CompanyName:"Валентина Литинецкая",
          Name: 'Лекция. Загадки еврейского искусства. От слова к образу и обратно',
          Age: '12+',
          Description: "Что такое искусство иудаики?\nКак расшифровывается еврейские символы?\nМешает ли вторая заповедь созданию изображений?\nКакова роль еврейских художников в современном искусстве?\nПопробуем ответить на эти вопросы на примере экспонатов музея АНУ.\nВстречу проведет Валентина Литинецкая -\nискусствовед, лицензированный гид по Израилю, экскурсовод Музея АНУ в Тель Авиве и Музея Израиля в Иерусалиме",
          SocialNetwork: {
          Instagram: "",
          Facebook: "",
          Telegram: "",
          },
          AggregatorLink:"",
          Genre:"лекция",
          Date:"1 июля, 17.00",
          id: 4464,
          },   
          
          {
            CompanyName:"Образовательный центр Окей",
            Name: 'Лекция о высшем образовании',
            Age: '16+',
            Description: 'Учебный Центр Окей специализируется в подготовке к вступительным экзаменам в вузы Израиля с 1996 года (психометрия, яэль, амирнэт). Директор центра Окей, Олег Колдобский расскажет про то, как поступить в израильский университет по максимально короткой траектории и ответит на вопросы. Центр Oкей – официальный партнер Министерства Абсорбции Израиля, Управления по делам студентов-репатриантов, Министерства Образования Израиля, Министерства Обороны Израиля, Еврейского Агентства Сохнут, Тель Авивского университета, Еврейского университета в Иерусалиме, университета имени Бар Илана, университета имени Бен Гуриона в Негеве, Техниона и Хайфского университета.',
            SocialNetwork: {
            Instagram: "",
            Facebook: "",
            Telegram: "",
            },
            AggregatorLink:"",
            Genre:"лекция",
            Date:"1 июля, 18.30",
            id: 4465,
            },            

  {
   CompanyName:"Дина Гербек",
   Name: 'Презентация книги- Лиса по имени Настасья',
   Age: '6+',
   Description:"Ручная лисица Настасья, выведенная в институте генетики, умеет читать, писать, пользуется планшетом и телефоном, но её хозяйка об этом не подозревает. Она вечно недовольна Настасьей и даже собирается запереть её в клетке на все новогодние праздники! Ночью Настасья сбегает и решает вернуться в соседний город к профессору, у которого жила с детства. Изучая карту, она сознательно выбирает дорогу через лес - очень уж хочется приключений. Разумеется, это сказка. Или не совсем? Генетически одомашненные лисицы действительно выведены в Новосибирском Академгородке. Историю их создания изучают биологи всего мира. Самый известный генетический эксперимент ХХ столетия теперь есть в детской книге в виде приключений отчаянной лисы. Автор, биолог и научный журналист Дина Гербек, расскажет о Настасье и её прототипах, выдумках и реальных фактах. Мы посмотрим видео с лисицами, прослушаем записи их голосов и попытаемся разгадать их смысл. Победитель получит книгу. ",
   SocialNetwork: {
  Instagram: "",
   Facebook: "https://www.facebook.com/dina.golubeva.9",
   Telegram: "",
   },
   AggregatorLink:"",
   Genre:"презентация",
   Date:"2 июля, 12.00",
   id: 4466,
   },

   {
    CompanyName:"Ольга Дробот",
    Name: 'Чтение для жаркого лета – два хороших романа о северной жизни',
    Age: '12+',
    Description: 'Скандинавист и переводчик Ольга Дробот расскажет о двух современных семейных сагах — "Полубрате" Ларса Соби Кристенсена и "Морском кладбище" Аслака Нуре. Одна скорее детектив, вторая – роман взросления. Обе — международные бестселлеры, переведенные на множество языков, истинные page turner, от которых невозможно оторваться: семейные тайны и мифы, реликвии и наследство, соперничество сиблингов, и, конечно, сильные женщины, на чьи плечи ложится весь груз ответственности.  Ольга Дробот — ведущий скандинавский переводчик на русский и с удовольствием ответит на ваши вопросы.',
    SocialNetwork: {
    Instagram: "",
    Facebook: "",
    Telegram: "",
    },
    AggregatorLink:"",
    Genre:"лекция",
    Date:"2 июля, 16.30",
    id: 4467,
    },   
   

  {
   CompanyName:"Рубен Давид Гонсаллес Гальего",
   Name: 'Творческая встреча с писателем',
   Age: '16+',
   Description:'"Белое на черном". Беседа с читателями о русской литературе и русском языке известного писателя. Рубен Давид Гонзалес Гальего и читатели поговорят о роли русского языка в наше очень непростое время.',
   SocialNetwork: {
  Instagram: "",
   Facebook: "https://www.facebook.com/rubendavid.gonzalezgallego",
   Telegram: "",
   },
  AggregatorLink:"https://eventbuzz.co.il/galyego",
   Genre:"презентация",
   Date:"2 июля, 19.00",
   id: 4468,
   },

   {
    CompanyName:"Fusion Choir!",
    Description:"Мы Хор! ‌‌Но совсем не такой хор, где вам нужно пройти прослушивание, знать сольфеджио и ноты, а потом вам еще и могут сказать, что вы поёте так себе. И даже если вас таки возьмут, вы будете нудно учить песни в духе «эх дороги, мои дороги». Хотя, песня красивая, может быть мы её ещё споём.Мы про свободу, счастье, легкость и любимые песни. Никаких прослушиваний, умеете вы петь или нет — это совершенно неважно! Главное, что вы хотите) В этом и есть главный смысл и ценность хорового пения — даже если вы не попали ни в одну ноту, то гармония хора вас поддержит и все вместе мы будем звучать так, словно нас хоть сейчас в Парижскую оперу.А репертуар какой? Спросите вы. ‌И мы ответим — полный fusion! От Бритни Спирс через Короля и Шута до Меладзе, с заходом к The Beatles и Coldplay. Поём то, что любим. Что годами бережно хранится в наших плейлистах, и то, чему нет-нет, да подпоем вместе с друзьями в такси.Приходите и пойте вместе с нами! ",
    SocialNetwork: {
   Instagram: "https://www.instagram.com/fusion_choir  Только завели, ещё пустой. Но на днях начнём наполнять",
    Facebook: "",
    Telegram: "https://t.me/fusionchoir",
    },
    AggregatorLink:"",
    Genre:"",
    Date:"",
    id: 4469,
    },
 
  ]

  export default ParticipantsData