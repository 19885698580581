import Reviews from '../../components/Reviews/Reviews'

function ReviewsPage() {
    return(
        <>
            <Reviews/>
        </>
    )
}

export default ReviewsPage;